@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Pacifico&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+FR+Moderne:wght@100..400&display=swap');

.App {
    
    min-height: 100vh;
    display: flex;
    font-family: "Varela Round", sans-serif;
    overflow-x: hidden;
    
    /*background-image: url('../public/assets/images/background/bg-min.png');
    
    background-repeat: repeat;
    background-size: "cover";
    scroll-behavior: unset;
    overflow-y: hidden; */
    /* background-color: rgb(238, 251, 254); */
    
}


::-webkit-scrollbar{
    width: 0px;
}

::-webkit-scrollbar-thumb{
    background: rgba(136, 136, 136, 0.281);
}

/*Handle on Hover*/
::-webkit-scrollbar-thumb:hover{
    background: "#555";
}

.mainBox {
    /* background-color: rgb(227, 241, 255); */
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    2.5% {
        transform: rotate(5deg);
    }

    5% {
        transform: rotate(0eg);
    }

    7.5% {
        transform: rotate(-5deg);
    }

    10% {
        transform: rotate(0deg);
    }
        
    
        12.5% {
            transform: rotate(5deg);
        }
    
        15% {
            transform: rotate(0eg);
        }
    
        17.5% {
            transform: rotate(-5deg);
        }
    
        20% {
            transform: rotate(0deg);
        }
    100% {
        transform: rotate(0deg);
    }
}

.shake {
    animation: shake 3s ease-in-out 3s infinite;
}

@keyframes shine {
    0% {
        background-position: -200% center;
    }

    100% {
        background-position: 200% center;
    }
}

.shine-button {
    position: relative;
    overflow: hidden;
    color: white;
    background:
        linear-gradient(90deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 75%);
    background-size: 200% auto;
    animation: shine 3s linear infinite;
    transition: background 0.4s ease;
}

